<template>
  <div>
    <h3>Store Front</h3>

    <v-btn
        text
        :to="{ name: 'manage-websites', params: { domainname: siteUrl }}"
        outlined
        class="btn btn-lg btn-secondary-main"
        style="background:transparent;"
    >
      <i class="fas fa-arrow-left"></i> Back
    </v-btn>
    <span class="btn btn-label-primary btn-sm btn-bold kt-margin-r-10 pull-right">
      <i class="fa fa-globe"></i>
      {{ siteUrl }}
    </span>
    <v-app>
      <div class="row mt-2" v-if="loading">
        <div class="col-md-12">
          <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
          <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
          <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
        </div>
      </div>
      <div class="row mt-2" v-if="!loading && pageSetting">
        <div class="col-xl-12">
          <v-card flat>
            <v-card-text>
              <v-row class="pa-10">
                <v-col cols="12">
                  <h3>Store Header Setting</h3>
                </v-col>
                <v-col cols="12">
                  <v-switch
                      @change="updateHomePageSetting"
                      v-model="pageSetting.is_system_ecommerce_page"
                      label="Store Front"
                      hint="Enable Store Catalog Homepage Layout"
                  ></v-switch>
                  <v-switch
                      @change="updateSetting"
                      v-model="pageSetting.is_system_ecommerce_header_enable"
                      label="Storefront additional Header"
                  ></v-switch>

                  <v-switch
                      @change="updateSetting"
                      v-model="pageSetting.is_system_ecommerce_footer_enable"
                      label="Storefront additional Footer"
                  ></v-switch>

                  <v-btn
                      :disabled="!pageSetting.is_system_ecommerce_header_enable"
                      @click.once="editPage(ecomStoreHeaderPage.id)"
                      color="primary"
                      class="mr-1"
                  >Edit Storefront Additional Header
                  </v-btn>

                  <v-btn
                      :disabled="!pageSetting.is_system_ecommerce_footer_enable"
                      @click.once="editPage(ecomStoreFooterPage.id)"
                      color="primary"
                  >Edit Storefront Additional Footer
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <h3>Storefront Layout Settings</h3>
                </v-col>
                <v-col cols="12">
                  <v-card outlined>
                    <v-card-text>
                      <v-row>
                        <v-col cols="8">
                          <vue-nestable
                              v-model="frontStoreContents"
                              @change="sortNow(frontStoreContents)"
                          >
                            <template slot-scope="{ item }">
                              <!-- Handler -->
                              <VueNestableHandle :item="item">
                                <i class="fas fa-bars"/>
                              </VueNestableHandle>

                              <!-- Content -->
                              <span>{{ item.title }} ({{ item.type }})</span>
                              <span class="btn-group">
                                  <a href="#" @click="editSelected(item)">
                                    <img
                                        src="@/assets/media/icons/edit-tools.png"
                                        class="edit_icon"
                                    />
                                  </a>
                                  <a href="#" @click.prevent="deleteFront(item.id)">
                                    <img
                                        src="@/assets/media/icons/send-to-trash.png"
                                        class="delete_icon"
                                    />
                                  </a>
                                </span>
                            </template>
                          </vue-nestable>
                        </v-col>
                        <v-col cols="4" class="text-right">
                          <v-btn color="primary" @click="openDialog">
                            <i class="fa fa-plus-circle"></i>Add New Section
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <div class="justify-end"></div>
            </v-card-actions>
          </v-card>
        </div>
      </div>
      <v-dialog scrollable v-model="storeFrontDialog" persistent max-width="900">
        <v-card>
          <v-card-title class="headline">Store Front (HomePage) Content</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" v-if="selectedItem!=null">
                <v-tabs v-model="tab" background-color="transparent" grow>
                  <v-tab>Banner</v-tab>
                  <v-tab>Catalogs</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item key="Banner">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field outlined v-model="selectedItem.title" dense
                                      label="Title"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-file-input
                            outlined
                            v-model="selectedItem.banner"
                            dense
                            show-size
                            counter
                            label="banner"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="12" v-if="selectedItem.type =='banner'">
                        <progressive-img
                            :src="selectedItem.banner_image.real"
                            :lazy-src="selectedItem.banner_image.real"
                            aspect-ratio="1"
                            class="grey lighten-2"
                            max-width="500"
                            max-height="300"
                        >
                          <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular indeterminate
                                                   color="grey lighten-5"></v-progress-circular>
                            </v-row>
                          </template>
                        </progressive-img>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                            outlined
                            v-model="selectedItem.banner_alt_text"
                            dense
                            label="Banner Alt Text"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                            outlined
                            v-model="selectedItem.banner_link"
                            dense
                            label="Banner Link"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                            label="Description"
                            outlined
                            v-model="selectedItem.description"
                            dense
                        ></v-textarea>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                            :items="columnNumber"
                            label="column number"
                            item-text="label"
                            item-value="value"
                            outlined
                            v-model="selectedItem.column_number"
                            dense
                        ></v-select>
                      </v-col>
                      <!-- <v-col cols="4">
                        <v-select
                          :items="productNumber"
                          item-text="label"
                          item-value="value"
                          label="product number"
                          outlined
                          v-model="selectedItem.product_number"
                          dense
                        ></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          outlined
                          v-model="selectedItem.sort_position"
                          dense
                          label="Position"
                        ></v-text-field>
                      </v-col>-->
                      <v-col cols="12" class="text-right">
                        <v-btn @click="closeDialog" text>Cancel</v-btn>
                        <v-btn :loading="loading" @click="updateFrontStoreContent" text>Update
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item key="catalogs">
                    <v-row>
                      <v-col cols="12" v-if="changeCatalog">
                        <cascader
                            style="width:100%;"
                            size="large"
                            :options="catlogList"
                            v-model="catlog_edited"
                            :fieldNames="{
                                                    label: 'title', value: 'id', children: 'children'
                                                    }"
                            placeholder="Please select Category"
                            change-on-select
                        />
                      </v-col>
                      <v-col cols="12" v-if="selectedItem.type =='catalogs'">
                        Category :
                        <Strong v-text="selectedItem.catalog.title"></Strong>
                        <v-btn @click="changeCatalog = true;" dense class="ml-3 btn btn-primary">
                          Change Category
                        </v-btn>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field outlined v-model="selectedItem.title" dense
                                      label="Title"></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                            :items="columnNumber"
                            label="column number"
                            item-text="label"
                            item-value="value"
                            outlined
                            v-model="selectedItem.column_number"
                            dense
                        ></v-select>
                      </v-col>
                      primary
                      <!-- <v-col cols="4">
                        <v-select
                          :items="productNumber"
                          item-text="label"
                          item-value="value"
                          label="product number"
                          outlined
                          v-model="selectedItem.product_number"
                          dense
                        ></v-select>
                      </v-col>-->
                      <!-- <v-col cols="4">
                        <v-text-field
                          outlined
                          v-model="selectedItem.sort_position"
                          dense
                          label="Position"
                        ></v-text-field>
                      </v-col>-->
                      <v-col cols="12" class="text-right">
                        <v-btn @click="closeDialog" text>Cancel</v-btn>
                        <v-btn
                            :loading="loading"
                            @click="updateFrontStoreContent"
                            color="primary"
                        >Update
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
              <v-col cols="12" v-else>
                <v-tabs v-model="tab" background-color="transparent" grow>
                  <v-tab>Banner</v-tab>
                  <v-tab>Catalogs</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item key="Banner">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field outlined v-model="frontShop.title" dense
                                      label="Title"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-file-input
                            outlined
                            v-model="frontShop.banner"
                            dense
                            show-size
                            counter
                            label="banner"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                            outlined
                            v-model="frontShop.banner_alt_text"
                            dense
                            label="Banner Alt Text"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                            outlined
                            v-model="frontShop.banner_link"
                            dense
                            label="Banner Link"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                            label="Description"
                            outlined
                            v-model="frontShop.description"
                            dense
                        ></v-textarea>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                            :items="columnNumber"
                            label="column number"
                            item-text="label"
                            item-value="value"
                            outlined
                            v-model="frontShop.column_number"
                            dense
                        ></v-select>
                      </v-col>
                      <!-- <v-col cols="4">
                        <v-select
                          :items="productNumber"
                          item-text="label"
                          item-value="value"
                          label="product number"
                          outlined
                          v-model="frontShop.product_number"
                          dense
                        ></v-select>
                      </v-col>-->
                      <!-- <v-col cols="4">
                        <v-text-field outlined v-model="frontShop.position" dense label="Position"></v-text-field>
                      </v-col>-->
                      <v-col cols="12" class="text-right">
                        <v-btn @click="closeDialog" text>Cancel</v-btn>
                        <v-btn :loading="loading" @click="createFrontStoreContent" text>Save
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item key="catalogs">
                    <v-row>
                      <v-col cols="12">
                        <cascader
                            style="width:100%;"
                            size="large"
                            :options="catlogList"
                            v-model="frontShop.catalog_id"
                            :fieldNames="{
                                                        label: 'title', value: 'id', children: 'children'
                                                        }"
                            placeholder="Please select Category"
                            change-on-select
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field outlined v-model="frontShop.title" dense
                                      label="Title"></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                            :items="columnNumber"
                            label="column number"
                            item-text="label"
                            item-value="value"
                            outlined
                            v-model="frontShop.column_number"
                            dense
                        ></v-select>
                      </v-col>
                      <!-- <v-col cols="4">
                        <v-select
                          :items="productNumber"
                          item-text="label"
                          item-value="value"
                          label="product number"
                          outlined
                          v-model="frontShop.product_number"
                          dense
                        ></v-select>
                      </v-col>-->
                      <!-- <v-col cols="4">
                        <v-text-field
                          outlined
                          v-model="frontShop.sort_position"
                          dense
                          label="Position"
                        ></v-text-field>
                      </v-col>-->
                      <v-col cols="12" class="text-right">
                        <v-btn @click="closeDialog" text>Cancel</v-btn>
                        <v-btn :loading="loading" @click="createFrontStoreContent" text>Save
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";

import WebsiteSetting from "@/services/Websites/WebsiteSettingService";
import ProductCatalogService from "@/services/store/ProductCatalogService";
import {VueNestable, VueNestableHandle} from "vue-nestable";
import Cascader from "ant-design-vue/lib/cascader";
import "ant-design-vue/dist/antd.css";

import WebsitePage from "@/services/Websites/WebsitePageService";
import WebsiteFrontStoreContent from "@/services/Websites/WebsiteFrontStoreContent";


import Website from "@/services/Websites/WebsiteService";

const websiteService = new Website();
const WebsiteSettingService = new WebsiteSetting();

const ProductCatalog = new ProductCatalogService();

const WebsitePageService = new WebsitePage();
const WebsiteFrontStore = new WebsiteFrontStoreContent();

export default {
  name: "store-front",
  components: {
    KTPortlet,
    VueNestable,
    VueNestableHandle,
    Cascader,
  },


  data() {
    return {
      isBusy: false,
      loading: true,
      changeCatalog: false,
      tab: null,
      dialog: false,
      rules: [
        value =>
            !value ||
            value.size < 2000000 ||
            "Avatar size should be less than 2 MB!"
      ],
      catlog_edited: [],
      ecomStoreHeaderPage: null,
      ecomStoreFooterPage: null,
      storeFrontDialog: false,
      pageSetting: null,
      catlogList: [],
      edit: false,
      selectedItem: null,
      website: null,
      frontShop: {
        title: "",
        banner: "",
        banner_alt_text: "",
        banner_link: "",
        description: "",
        column_number: "",
        product_number: "",
        position: "",
        is_active: ""
      },
      frontStoreContents: [
        {
          id: 1,
          banner: null,
          banner_alt_text: null,
          banner_link: null,
          catalog_id: null,
          column_number: 1,
          created: null,
          description: null,
          is_active: null,
          product_number: null,
          sort_position: null,
          title: null,
        }
      ],
      nestableFrontStoreContents: [],
      columnNumber: [
        {
          label: "1 Column",
          value: 12
        },
        {
          label: "2 Column",
          value: 6
        },
        {
          label: "3 Column",
          value: 4
        },
        {
          label: "4 Column",
          value: 3
        }
      ],
    };
  },
  methods: {
    openDialog() {
      this.storeFrontDialog = true;
    },
    closeDialog() {
      this.selectedItem = {};
      this.frontShop = {
        title: null,
        banner: null,
        banner_alt_text: null,
        banner_link: null,
        description: null,
        column_number: null,
        product_number: null,
        position: null,
        is_active: null
      };
      this.storeFrontDialog = false;
    },
    showDialog() {
      this.dialog = true;
    },

    editPage(id) {
      if (this.website.is_gridless_builder === 1) {
        let url =
            process.env.VUE_APP_SERVER_URL +
            "pagebuilder/" +
            this.siteUrl +
            "/website/editor/gridless/" +
            id +
            "#builder";
        window.open(url, "_blank");
      } else {
        let url =
            process.env.VUE_APP_SERVER_URL +
            "pagebuilder/" +
            this.siteUrl +
            "/website/editor/" +
            id +
            "/update#pagebuilder";
        window.open(url, "_blank");
      }
    },
    editSelected(item) {
      this.tab = item.type == "catalogs" ? 1 : 0;
      this.selectedItem = item;
      this.selectedItem.banner = null;

      this.openDialog();
    },
    getFrontStoreContent() {
      WebsiteFrontStore.paginate(this.siteUrl).then(res => {
        this.frontStoreContents = res.data;
      });
    },
    getHeaderPage(type = "ecommerce_header", flag = null) {
      WebsitePageService.paginate(this.siteUrl, type, flag).then(res => {
        this.ecomStoreHeaderPage = res.data.pages[0];
      });
    },
    getFooterPage(type = "ecommerce_footer", flag = null) {
      WebsitePageService.paginate(this.siteUrl, type, flag).then(res => {
        this.ecomStoreFooterPage = res.data.pages[0];
      });
    },
    getSettings() {
      WebsiteSettingService.paginate(this.siteUrl).then(res => {
        this.pageSetting = res.data;
        this.loading = false;
        this.pageSetting.ecommerce_root_catalog_id ? this.getCatalogs(this.pageSetting.ecommerce_root_catalog_id) : '';

      });
    },
    getCatalogs(id) {
      ProductCatalog.getCatlogByRootId(id).then(res => {
        this.catlogList = res.data;
      });
    },
    sortNow(storeFront) {
      WebsiteFrontStore.sort(this.siteUrl, storeFront).then(res => {
        this.$snotify.success("Sorted");

      });
    },
    updateFrontStoreContent() {
      this.loading = true;

      let type = this.tab == 0 ? "banner" : "catalogs";
      this.selectedItem.type = type;
      if (this.type == "catalogs") {
        this.selectedItem.catalog_id = this.catlog_edited
            .slice(-1)
            .pop();
      }
      if (this.catlog_edited) {
        if (this.catlog_edited.length > 0)
          this.selectedItem.catalog_id = this.catlog_edited[this.catlog_edited.length - 1];
        else
          this.selectedItem.catalog_id = this.catlog_edited;
      }

      let fd = new FormData();
      for (var key in this.selectedItem) {
        if (
            key === "banner" &&
            (this.selectedItem["banner"] == null ||
                this.selectedItem["banner"] == undefined)
        ) {
        } else if (
            key === "catalog_id" &&
            (this.selectedItem["catalog_id"] == null ||
                this.selectedItem["catalog_id"] == undefined)
        ) {
          fd.append(key, "");
        } else {
          fd.append(key, this.selectedItem[key]);
        }
      }
      WebsiteFrontStore.update(this.siteUrl, this.selectedItem.id, fd).then(
          res => {
            this.loading = false;

            this.getFrontStoreContent();
            this.resetFrontContent();
            this.closeDialog();
            this.$snotify.success("Settings  Updated");
          }
      );
    },
    updateHomePageSetting() {
      this.$confirm(
          {
            message: `Are you sure?`,
            button: {
              no: 'No',
              yes: 'Yes'
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: confirm => {
              if (confirm) {
                if (this.pageSetting.is_system_ecommerce_page) {
                  this.pageSetting.home_page_id = null;
                }
                this.updateSetting();
              }
            }
          }
      )

    },
    deleteFront(id) {
      this.$confirm(
          {
            message: `Are you sure?`,
            button: {
              no: 'No',
              yes: 'Yes'
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: confirm => {
              if (confirm) {
                WebsiteFrontStore.delete(this.siteUrl, id).then(res => {
                  this.$snotify.success("Deleted successfully");
                  this.getFrontStoreContent()
                })
              }
            }
          }
      )

    },
    updateSetting() {
      this.$confirm(
          {
            message: `Are you sure?`,
            button: {
              no: 'No',
              yes: 'Yes'
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: confirm => {
              if (confirm) {
                WebsiteSettingService.update(
                    this.siteUrl,
                    this.pageSetting.id,
                    this.pageSetting
                ).then(res => {
                  this.$snotify.success("Settings  Updated");
                  this.getSettings();
                });
              }
            }
          }
      )

    },
    createFrontStoreContent() {
      this.loading = true;
      let type = this.tab == 0 ? "banner" : "catalogs";
      this.frontShop.type = type;
      if (this.tab === 1) {
        this.frontShop.catalog_id = this.frontShop.catalog_id.slice(-1).pop();
      }

      let fd = new FormData();
      for (var key in this.frontShop) {
        if (
            key === "banner" &&
            (this.frontShop["banner"] == null ||
                this.frontShop["banner"] == undefined)
        ) {
        } else if (
            key === "catalog_id" &&
            (this.frontShop["catalog_id"] == null ||
                this.frontShop["catalog_id"] == undefined)
        ) {
          fd.append(key, "");
        } else {
          fd.append(key, this.frontShop[key]);
        }
      }
      WebsiteFrontStore.create(this.siteUrl, fd).then(res => {
        this.loading = false;

        this.getFrontStoreContent();
        this.resetFrontContent();
        this.closeDialog();
        this.$snotify.success("Settings  Created");
      });
    },
    resetFrontContent() {
      this.frontShop = null;
      this.selectedItem = null;
    },


    reset() {
      this.dialog = false;
      this.isBusy = false;
      this.edit = false;
      this.$v.$reset();
    },


    toggleBusy() {
      this.isBusy = !this.isBusy;
    },
    getWebsite() {
      websiteService.get(this.siteUrl).then(res => {
        this.website = res.data.website;
      });
    },
  },

  computed: {

    siteUrl() {
      return this.$route.params.domainname;
    },
    generateSlug() {
      return this.slugify(this.page.title);
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Manage Pages", route: "/websites"}
    ]);
    this.getSettings();
    this.getFrontStoreContent();

    this.getHeaderPage();
    this.getFooterPage();
    this.getWebsite();
    // this.getBlogCategory();
  }
};
</script>
<style lang="scss" scope>
/*
    * Style for nestable
    */


.nestable {
  position: relative;
}

.nestable-rtl {
  direction: rtl;
}

.nestable .nestable-list {
  margin: 0;
  padding: 0 0 0 40px;
  list-style-type: none;
}

.nestable-rtl .nestable-list {
  padding: 0 40px 0 0;
}

.nestable > .nestable-list {
  padding: 0;
}

.nestable-item,
.nestable-item-copy {
  margin: 10px 0 0;
}

.nestable-item:first-child,
.nestable-item-copy:first-child {
  margin-top: 0;
}

.nestable-item .nestable-list,
.nestable-item-copy .nestable-list {
  margin-top: 10px;
}

.nestable-item {
  position: relative;
}

.nestable-item.is-dragging .nestable-list {
  pointer-events: none;
}

.nestable-item.is-dragging * {
  opacity: 0;
  filter: alpha(opacity=0);
}

.nestable-item.is-dragging:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(106, 127, 233, 0.274);
  border: 1px dashed rgb(73, 100, 241);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.nestable-drag-layer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
}

.nestable-rtl .nestable-drag-layer {
  left: auto;
  right: 0;
}

.nestable-drag-layer > .nestable-list {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  background-color: rgba(106, 127, 233, 0.274);
}

.nestable-rtl .nestable-drag-layer > .nestable-list {
  padding: 0;
}

.nestable [draggable="true"] {
  cursor: move;
}

.nestable-handle {
  display: inline;
}

.nestable-item .btn-group {
  float: right;
}

.nestable-item .nestable-item-content,
.kt-portlet__body ol.nestable-list .nestable-item-content {
  color: black;
  font-weight: bold;
  padding: 1rem 2rem;
  border-radius: 5px;
  background: #eceff0;
}

.nestable-handle {
  margin-right: 10px;
  cursor: grabbing !important;
}

.nestable-handle span {
  font-weight: bold !important;
}
</style>
